<template>
  <div>
    <v-container fluid class="pa-0 ma-0">
      <!-- Inicio -->
      <v-row no-gutters justify="center" v-if="showEncuesta == false">
        <v-col 
          :cols="($vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl) ? 6 : 12"
        >
          <v-card
            flat
            outlined
            tile
          >
            <v-img
              :src="require('../../assets/encuesta_clima_2024.webp')"
            ></v-img>
            <br/>
            <v-divider></v-divider>
            <v-card-title style="justify-content: center;">
              Bienvenido/a
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <p style="text-align: justify;">¡Queremos conocer tu opinión sobre el clima laboral!</p>
                  <p style="text-align: justify;">Te invitamos a participar en una encuesta que nos permitirá comprender mejor cómo te sientes en nuestra empresa. No hay respuestas correctas o incorrectas; lo importante es que compartas tu experiencia y percepción personal de manera sincera y espontánea.</p>
                  <p style="text-align: justify;">
                    <ol type="I">
                      <li>La encuesta es completamente anónima y confidencial.</li>
                      <li>Desde el equipo de Recursos Humanos y la dirección de la empresa, procesaremos los resultados con el objetivo de identificar áreas de mejora y trabajar juntos en acciones que promuevan un entorno de trabajo más positivo.</li>
                    </ol>
                  </p>
                  <p style="text-align: justify;">¡Tu opinión es clave para seguir creciendo como empresa!</p>
                </v-col>
              </v-row>
              <v-form @submit.prevent="accederEncuesta()" ref="form">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="codigo"
                      clearable
                      dense
                      :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
                      :rules="[rules.required, rules.min]"
                      :type="show ? 'text' : 'password'"
                      label="Codigo de Acceso"
                      hint="Su codigo de acceso es único, personal e intransferible."
                      counter
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      class="mt-8"
                      type="submit"
                      tabindex="1"
                      :loading="load"
                      block
                    >
                      Ingresar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Encuesta Con Lista de Respuesta -->
      <v-row no-gutters justify="center" v-else>
        <v-col :cols="($vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl) ? 10 : 12">
          <v-card
            flat
            outlined
            :loading="load"
            :disabled="load"
            elevation="3"
          >
            <v-card-title class="pt-0 pl-0 pr-0">
              <v-toolbar
                flat
                color="primary"
              >
                <div v-if="escalas.length > 0 && index >= 0 && index < escalas.length" style="color: white;">Encuesta {{ index + 1 }}/{{ escalas.length }} - {{ escalas[index].pregunta_grupo_nombre }}</div>
              </v-toolbar>
            </v-card-title>
            <v-card-text v-if="index <= escalas.length" class="pa-0">
              <v-form @submit.prevent="sigPreguntas()" ref="formEnc">
                <v-row no-gutters>
                  <v-row justify="center" v-if="index <= escalas.length">
                    <v-col cols="11" align="center">
                      <v-app-bar>
                        <v-toolbar-title class="custom-font-size"><strong>Lea con atención las siguientes afirmaciones y responda:</strong></v-toolbar-title>
                      </v-app-bar>
                    </v-col>
                  </v-row>
                  <!-- Encuesta de una sola opción -->
                  <v-col cols="12" class="py-1" v-if="index < escalas.length && tipoPregunta == 2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, ind) in preguntas"
                            :key="ind"
                            style="height: 80px;"
                          >
                            <td class="text-left">{{ item.pregunta_nombre }}</td>
                            <td class="text-center">
                              <v-autocomplete
                                v-model="item.escala_medicion_valor_id"
                                item-text="valor_nombre"
                                item-value="escala_medicion_valor_id"
                                :items="respuestas"
                                dense
                                label="Seleccione una Opción"
                                clearable
                                :rules="[rules.preg]"
                              ></v-autocomplete>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <!-- Encuesta con checkbox -->
                  <v-col cols="12" class="py-1" v-if="index < escalas.length && tipoPregunta == 3">
                    <v-row v-if="index < escalas.length && preguntas.length > 0" class="pa-4">
                      <v-col cols="12" class="py-1 text-center">
                        <strong>{{ preguntas[0].pregunta_nombre }}</strong>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, ind) in respuestas"
                            :key="ind"
                            style="height: 80px;"
                          >
                            <td class="text-left">{{ item.valor_nombre }}</td>
                            <td class="text-right">
                              <v-checkbox
                                v-model="item.selected"
                                :true-value="1"
                                :false-value="0"
                                hide-details
                                dense
                              ></v-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" class="py-1" v-if="index >= escalas.length">
                    <v-textarea
                      outlined
                      dense
                      v-model="comentario"
                      label="Comentarios Adicionales"
                      class="ma-2"
                      :rules="[rules.comentario]"
                      counter="1000"
                      :key="comKey"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                rounded
                color="error"
                dark
                @click.prevent="finalizarEncuesta()"
                v-if="index >= escalas.length"
              >
                Finalizar
              </v-btn>
              <v-btn
                rounded
                color="success"
                dark
                @click.prevent="sigPreguntas()"
                v-else
              >
                Siguiente
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ModalDatosEncuesta 
      :datos="dialogDatosPersonales"
      @setearDatosEncClima="setDatosEncClima"
    />
  </div>
</template>

<script>
import { cadenaVacia, order_list_by } from '../../util/utils'
import ModalDatosEncuesta from '../../components/ModalDatosEncuesta.vue';
export default {
  data() {
    return {
      codigo: '',
      show: false,
      rules: {
        required: value => !!value || 'Debe ingresar el código de Acceso para poder empezar.',
        min: v => v.length <= 8 || 'El Codigo no debe superar los 8 caracteres.',
        preg: value => !!value || 'Debe Indicar el Valor para la Pregunta',
        comentario: v => (v.length <= 1000) || 'El Comentario no puede superar los 1000 caracteres.'
      },
      load: false,
      encuestaId: 0,
      showEncuesta: false,
      escalas: [],
      dialogDatosPersonales: {
        activo: false,
        antiguedad: [],
        encuesta_id: null
      },
      preguntas: [],
      preguntasTodas: [],
      respuestas: [],
      respuestasTodas: [],
      index: 0,
      tipoPregunta: 0,
      comentario: '',
      comKey: 0
    }
  },
  components: {
    ModalDatosEncuesta
  },
  methods: {
    async accederEncuesta(){
      if (this.$refs.form.validate()){
        this.load = true
        let validatePeticion = await this.$store.dispatch('encuestas/acceder_encuesta', {codigo: this.codigo})
        this.load = false
        if (validatePeticion.resultado == 0){
          this.showEncuesta = false
          this.$store.dispatch('show_snackbar', {
            text: validatePeticion.message,
            color: 'error',
          })
          return
        }
        this.index = 0
        this.showEncuesta = true
        this.encuestaId = validatePeticion.encuesta_id
        this.escalas = validatePeticion.escalas
        this.preguntasTodas = validatePeticion.preguntas
        this.respuestasTodas = validatePeticion.respuestas
        order_list_by(this.escalas, 'orden', 'asc')
        order_list_by(this.preguntasTodas, 'enc_historial_preg_id', 'asc')
        order_list_by(this.respuestasTodas, 'escala_medicion_valor', 'desc')
        if (validatePeticion.completa_clasificacion == 0){
          this.dialogDatosPersonales.antiguedad = validatePeticion.datos_encuesta.filter(element => element.escala_medicion_id == 9)
          order_list_by(this.dialogDatosPersonales.antiguedad, 'valor', 'asc')
          this.dialogDatosPersonales.encuesta_id = validatePeticion.encuesta_id
          this.dialogDatosPersonales.activo = true
        }
        this.noBackBrowser()
        this.obtenerPreguntas(this.index)
      }
    },
    noBackBrowser(){
      window.location.hash="no-back-button"
      window.location.hash="Again-No-back-button"
      window.onhashchange = function(){
        window.location.hash="no-back-button"
      }
    },
    obtenerPreguntas(indice){
      if (this.index < this.escalas.length){
        this.preguntas = []
        this.respuestas = []
        this.tipoPregunta = 0
        let escala_id = this.escalas[indice].escala_medicion_id
        let preg = this.preguntasTodas.filter(element => element.escala_medicion_id == escala_id)
        if (preg.length > 0){
          this.tipoPregunta = preg[0].pregunta_tipo_id
          this.preguntas = preg
          let resp = this.respuestasTodas.filter(element => element.escala_medicion_id == escala_id)
          if (resp.length > 0){
            this.respuestas = resp
            if (this.tipoPregunta == 3){
              for (let id in this.respuestas){
                this.respuestas[id].selected = 0
              }
            }
          }
          else{
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudieron obtener las posibles respuestas para esta instancia. Revisar.',
              color: 'error',
            })
            return
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudieron obtener las posibles preguntas para esta instancia. Revisar.',
            color: 'error',
          })
          return
        }
        if (this.index != 0) this.$refs.formEnc.resetValidation()
      }
    },
    async sigPreguntas(){
      if (this.tipoPregunta == 2){
        if (!this.$refs.formEnc.validate()) return
        this.load = true
        let savePregPeticion = await this.$store.dispatch('encuestas/save_grupo_preguntas', {
          preguntas: this.preguntas, 
          encuesta_id: this.encuestaId
        })
        this.load = false
        if (savePregPeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: savePregPeticion.message,
            color: 'error',
          })
          return
        }
      }else{
        // controlo cuantas afirmaciones tildaron
        let tiltadas = this.respuestas.filter(element => element.selected == 1).length
        if (tiltadas < 5){
          this.$store.dispatch('show_snackbar', {
            text: 'Seleccione al menos 5 opciones.',
            color: 'warning',
          })
          return
        }
        // le sumo las respuestas a la pregunta cargada
        this.preguntas[0].respuesta = this.respuestas
        this.load = true
        let savePregPeticion = await this.$store.dispatch('encuestas/save_grupo_preguntas', {
          preguntas: this.preguntas, 
          encuesta_id: this.encuestaId
        })
        this.load = false
        if (savePregPeticion.resultado == 0){
          this.$store.dispatch('show_snackbar', {
            text: savePregPeticion.message,
            color: 'error',
          })
          return
        }
      }
      this.index++
      this.obtenerPreguntas(this.index)
    },
    async finalizarEncuesta(){
      if (cadenaVacia(this.comentario)){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un Comentario.',
          color: 'error',
        })
        return
      }
      this.load = true
      let finalizaPeticion = await this.$store.dispatch('encuestas/finalizar_encuesta', {
        encuesta_id: this.encuestaId,
        comentario: this.comentario
      })
      this.load = false
      if (finalizaPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: finalizaPeticion.message,
          color: 'error',
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Se guardó la encuesta con éxito.',
        color: 'success',
      })
      setTimeout(() => [location.reload()], 2000)
    },
    setDatosEncClima(value){
      this.dialogDatosPersonales.activo = value
    }
  },
  watch: {
    codigo: function(){
      if (cadenaVacia(this.codigo)) this.codigo = ''
    },
    comentario: function(){
      if (this.comentario.length > 1000){
        this.comentario = this.comentario.substring(0, 1000)
        this.comKey++
      } 
    }
  },
}
</script>

<style scoped>
  .custom-font-size {
    font-size: 13px; /* Ajusta este valor según tus necesidades */
  }
</style>